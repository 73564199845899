import React, { useEffect, useRef } from "react"

function AudioPlayer({ url }) {
  const audioRef = useRef()

  useEffect(() => {
    audioRef.current?.load()
  }, [url])

  return (
    <div className="col-lg-12 text-center py-3">
      <audio controls style={{ width: "80%" }} ref={audioRef}>
        <source src={url} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    </div>
  )
}

export default AudioPlayer
