import React, { useEffect, useRef } from "react"

function VideoPlayer({ url }) {
  const videoRef = useRef()

  useEffect(() => {
    videoRef.current?.load()
  }, [url])

  return (
    <>
      <div className="col-lg-12 text-center pt-3">
        <div className="row row-cols-lg-auto g-2 align-items-center mb-3">
          <div className="col-12">Player Style</div>
          <div className="col-12">
            <select name="video_player_style" id="video_player_style" className="form-select">
              <option value="Default">Regular Default Player (16:9 Horizontal)</option>
              <option value="Vertical">Vertical Player (9:16 Vertical)</option>
              <option value="Square">Square Player (1:1)</option>
            </select>
          </div>
        </div>
      </div>
      <div className="col-lg-12 text-center py-3">
        <div className="ratio ratio-16x9">
          <video controls className="embed-responsive-item" ref={videoRef}>
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </>
  )
}

export default VideoPlayer
