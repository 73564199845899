import React from "react"
import Accordion from "react-bootstrap/Accordion"

import Privacy from "../Doc/MoreOptions/privacy"
import Tags from "../Doc/MoreOptions/tags"

import "../../../css/bootstrap-tagsinput.css"
import "./assetTypes.css"

const MoreOptions = ({
  type,
  tags,
  setTags,
  tempTags,
  setTempTags,
  docPrivacy,
  setDocPrivacy,
  whoCanModify,
  setWhoCanModify,
  privacyPassword,
  whoCanCopy,
  setWhoCanCopy,
}) => {
  return (
    <div className="col-lg-12 mb-3" id="more_panel">
      <Accordion>
        <Accordion.Item eventKey="1" className="panel panel-grey">
          <Accordion.Header>
            <span className="panel-heading-icon">
              <i className="bi bi-three-dots" />
            </span>
            <span className="panel-heading-title">More Options</span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  {type === "pdf" && (
                    <div className="col-lg-12 mb-3">
                      <div className="colHeading">
                        <strong>
                          <i className="bi bi-pencil-fill" /> e-Signature Overlay
                        </strong>
                      </div>
                      <div className="dh-block-body">
                        <div className="toggleSwitch">
                          <input type="checkbox" value="1" name="eSignOverlay" id="eSignOverlay" />
                          <label>e-Signature Overlay</label>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-12 mb-3">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-tags-fill" /> Tags
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      <Tags tags={tags} setTags={setTags} tempTags={tempTags} setTempTags={setTempTags} />
                    </div>
                  </div>
                  <div className="col-lg-12 assetPrivacyContainer">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-lock-fill" /> Privacy
                      </strong>
                    </div>
                    <Privacy
                      docPrivacy={docPrivacy}
                      setDocPrivacy={setDocPrivacy}
                      whoCanModify={whoCanModify}
                      setWhoCanModify={setWhoCanModify}
                      privacyPassword={privacyPassword}
                      whoCanCopy={whoCanCopy}
                      setWhoCanCopy={setWhoCanCopy}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  {type !== "audio" && (
                    <div className="col-lg-12 mb-3">
                      <div className="colHeading">
                        <strong>
                          <i className="bi bi-file-image" /> Featured Image
                        </strong>
                      </div>
                      <div className="dh-block-body">
                        <div className="folderIcon">
                          <img src={`${process.env.GATSBY_WRANGLER_URL}/StaticImages/no-image.jpg`} />
                        </div>
                        <button type="button" className="btn btn-default btnDocFormIconEdit">
                          <span
                            data-bs-toggle="tooltip"
                            data-placement="bottom"
                            title=""
                            data-bs-original-title="Edit folder image"
                            aria-label="Edit folder image"
                          >
                            <i className="bi bi-pencil-fill" />
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-12">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-chat-fill" /> Comments
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      Comments are
                      <div className="toggleSwitch">
                        <input type="checkbox" value="1" name="asset_comments" id="asset_comments" />
                        <label></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default MoreOptions
