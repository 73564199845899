import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import Accordion from "react-bootstrap/Accordion"
import { Form } from "react-bootstrap"

import Layout from "../../../components/Layout/layout"
import Seo from "../../seo"
import MoreOptions from "./moreOptions"
import NotFoundPage from "../../../pages/404"
import {
  DRIVE_TYPE_AUDIO,
  DRIVE_TYPE_IMAGE,
  DRIVE_TYPE_PDF,
  DRIVE_TYPE_VIDEO,
  getFileExtension,
  onDownload,
} from "../Service/driveListHelper"
import AxiosInstance from "../../../services/axiosInstance"
import VideoPlayer from "./videoPlayer"
import AudioPlayer from "./audioPlayer"

const File = ({ selectedDocId, type }) => {
  const [fileName, setFileName] = useState(""),
    [parentDoc, setParentDoc] = useState(""),
    [file, setFile] = useState(""),
    [fileExtension, setFileExtension] = useState(""),
    [tags, setTags] = useState([]),
    [tempTags, setTempTags] = useState([]),
    [docPrivacy, setDocPrivacy] = useState(""),
    [whoCanModify, setWhoCanModify] = useState(""),
    [privacyPassword, setPrivacyPassword] = useState(""),
    [whoCanCopy, setWhoCanCopy] = useState(""),
    [saveLoading, setSaveLoading] = useState(false),
    [filePath, setFilePath] = useState("")

  useEffect(() => {
    getFileData(selectedDocId).then(r => r)
  }, [])

  if (![DRIVE_TYPE_VIDEO, DRIVE_TYPE_AUDIO, DRIVE_TYPE_IMAGE, DRIVE_TYPE_PDF].includes(type)) {
    return <NotFoundPage />
  }

  const getFileData = async selectedDocId => {
    await AxiosInstance.get(`/drive/storage/detail/${selectedDocId}`).then(response => {
      fillFileData(response.data.storage)
    })
  }

  const fillFileData = fileDetail => {
    setFileName(fileDetail.driveAll.nameDisplay)
    setFile(fileDetail.file)
    setParentDoc(fileDetail.driveAll.parentID)
    setDocPrivacy(fileDetail.driveForm.moreOptions.doc_privacy ?? "")
    setWhoCanModify(fileDetail.driveForm.moreOptions.who_can_modify ?? "")
    setPrivacyPassword(fileDetail.driveForm.moreOptions.privacy_password ?? "")
    setWhoCanCopy(fileDetail.driveForm.moreOptions.who_can_copy ?? "")
    setTempTags(fileDetail.driveForm.moreOptions.doc_private_tags ?? [])
    setFileExtension(getFileExtension(fileDetail.file))
    setFilePath(process.env.GATSBY_WRANGLER_URL + fileDetail.file)
  }

  const icon = type => {
    switch (type) {
      case DRIVE_TYPE_PDF:
        return "bi bi-file-earmark-pdf-fill"
      case DRIVE_TYPE_AUDIO:
        return "bi bi-file-music-fill"
      case DRIVE_TYPE_IMAGE:
        return "bi bi-file-image"
      case DRIVE_TYPE_VIDEO:
        return "bi bi-file-earmark-play-fill"
    }
  }

  const submitStorage = e => {
    e.preventDefault()
    if (saveLoading) {
      return false
    }
    setSaveLoading(true)
    const formData = new FormData(e.target)
    let data = {
      userName: window.localStorage.getItem("userName"),
      docName: formData.get("storageName"),
      ownerID: formData.get("ownerID"),
      parentDoc: formData.get("parentDoc"),
      form: {
        moreOptions: {
          doc_private_tags: tempTags,
          doc_privacy: formData.get("doc_privacy"),
          who_can_modify: formData.get("who_can_modify"),
          privacy_password: formData.get("privacy_password"),
          who_can_copy: formData.get("who_can_copy"),
        },
      },
    }
    saveStorageData(data).then(r => r)
  }

  const saveStorageData = data => {
    return new Promise(async () => {
      await AxiosInstance.post(`/drive/storage/edit/${selectedDocId}`, data)
        .then(function (response) {
          setSaveLoading(false)
          console.log(response.data)
        })
        .catch(function (error) {
          setSaveLoading(false)
          if (error.response.status === 400) {
            console.log(error.response.data)
          } else if (error.response.data) {
            console.log(error.response.data.detail)
          }
        })
    })
  }

  return (
    <Layout>
      <Seo title={`${fileName} ${type.charAt(0).toUpperCase() + type.slice(1)}`} />
      <Form onSubmit={submitStorage} id={"storageForm"}>
        <div id="right-section" className={`h-100 asset-type-${type}`}>
          <div className="row g-0">
            <div className="col" id="page-urls">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row g-0">
                    <div className="col-sm-12 mb-3">
                      <div className="pageTitle_Editor">
                        <Link
                          to={`${parentDoc ? "/drive/folder/" + parentDoc : "/drive"}`}
                          className="goBack"
                        >
                          <i className="bi bi-arrow-90deg-left" />
                        </Link>
                        <button type="button" className="CD_UserType isHuman" data-bs-toggle="tooltip">
                          <i className={`${icon(type)}`} />
                        </button>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={fileName}
                          defaultValue={fileName}
                          name="storageName"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row g-0">
                    <div className="col-lg-12 mb-3">
                      <div className="row g-0">
                        <div className="col-lg-12 mb-3" id="asset_panel">
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0" className="panel panel-white">
                              <Accordion.Header>
                                <span className="panel-heading-icon">
                                  <i className={`${icon(type)}`} />
                                </span>
                                <span className="panel-heading-title">{fileName}</span>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="row">
                                  {type === "image" && (
                                    <div className="col-lg-12 text-center py-3">
                                      <img src={filePath} alt={file} width={"100%"} />
                                    </div>
                                  )}
                                  {type === "pdf" && (
                                    <div className="col-lg-12 text-center py-3">
                                      <div className="ratio ratio-16x9">
                                        <embed
                                          src={filePath}
                                          className="embed-responsive-item"
                                          type="application/pdf"
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {type === "audio" && <AudioPlayer url={filePath} />}
                                  {type === "video" && <VideoPlayer url={filePath} />}
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>

                        <MoreOptions
                          type={type}
                          tags={tags}
                          setTags={setTags}
                          tempTags={tempTags}
                          setTempTags={setTempTags}
                          docPrivacy={docPrivacy}
                          setDocPrivacy={setDocPrivacy}
                          whoCanModify={whoCanModify}
                          setWhoCanModify={setWhoCanModify}
                          privacyPassword={privacyPassword}
                          whoCanCopy={whoCanCopy}
                          setWhoCanCopy={setWhoCanCopy}
                        />

                        <div className="col-lg-12 text-center">
                          <button type="submit" className="btn btn-primary btn-lg">
                            <i className="bi bi-check2" /> Save Changes
                          </button>{" "}
                          <button
                            type="button"
                            className="btn btn-secondary btn-lg"
                            data-bs-toggle="tooltip"
                            title=""
                            data-bs-original-title="Download"
                            aria-label="Download"
                            onClick={e => onDownload(e, selectedDocId, fileName, fileExtension)}
                          >
                            <i className="bi bi-cloud-arrow-down-fill" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Layout>
  )
}
export default File
