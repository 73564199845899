import * as React from "react"
import PrivateRoute from "../../../../components/privateRoute"
import FileDetail from "../../../../components/Drive/File/detail"
import ReduxWrapper from "../../../../redux/reduxWrapper"

const FileDetails = props => {
  return (
    <PrivateRoute
      component={FileDetail}
      location={props.location}
      selectedDocId={props.id}
      type={props.type}
    />
  )
}

const WrappedPage = props => <ReduxWrapper element={<FileDetails {...props} />} />
export default WrappedPage
